<template>
  <common-anggota-create-update
    :headerText="headerText"
    :anggotaMdl="preceptorMdl"
  />
</template>


<script>
import { has } from "lodash";
import Konsulen from "../models/preceptor.js";


export default {
  name: "PreceptorCreateUpdate",
  props: ["headerText"],
  components: {
    CommonAnggotaCreateUpdate: () =>
      import("@/apps/anggota/views/CommonAnggotaCreateUpdate.vue"),
    // DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
  },
  data() {
    this.preceptorMdl = new Konsulen("anggota");
    return this.preceptorMdl.getObservables();
  },
  computed: {
    isCreate() {
      return !has(this.$route.params, "id");
    }
  },
  methods: {
    validateInput(field) {
      this.preceptorMdl.validate(field);
    }
  }
};
</script>

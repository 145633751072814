import AnggotaBase from "./anggotaBase.js"

const modelName = "preceptor";
const modelPath = "/anggota/";

class Preceptor extends AnggotaBase {
  constructor(overrideName) {
    let ovrName = overrideName ? overrideName : modelName;
    super(ovrName, {}, [], [], modelPath);
  }

  initObservables() {
    super.initObservables();
    this.observables.tipe = "dosen";
  }
}

export default Preceptor;